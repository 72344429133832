import img1 from '../Assets/BrownBearInteriorOne.jpg'
import img2 from '../Assets/BrownBearInteriorTwo.jpg'
import img3 from '../Assets/BrownBearInteriorThree.jpg'

export const coffeeFood = [
    {
        "src": img1 ,
        "alt": "Image 1 for carousel"
    },
    {
        "src":  img2,
        "alt": "Image 2 for carousel"
    },
    {
        "src": img3,
        "alt": "Image 3 for carousel"
    }
]
